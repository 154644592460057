import { parseMoment } from '@/utilities/moment/parse'
import { monthNums } from '@/utilities/Date/constants'

export const watch = {
  point() {
    const parsed = parseMoment({
      m: this.point
    })

    this.time = parsed.time

    this.$refs.datePicker.setValue2({
      dayOfMonth: parsed.dayOfMonth,
      month: monthNums[parsed.month],
      year: parsed.orbit
    })
  }
}
