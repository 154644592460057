import moment from 'moment'

export const methods = {
  update() {},
  setValue() {},
  getValue() {
    const { utcFormat } = this.$refs.datePicker.getValue()

    const final = moment(utcFormat)

    const time = this.$refs.timePicker.getValue()[24]
    const [hour, minute, second] = time.split(':')

    final.set({
      hour,
      minute,
      second
    })

    return {
      value: final.utcOffset(0, true).format()
    }
  }
}
