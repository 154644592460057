<template>
  <div :style="{ display: 'flex', marginBottom: '12px' }">
    <DatePicker ref="datePicker" :style="{ margin: 0 }" v-bind="datePickerProps" />
    <TimePicker ref="timePicker" v-model="time" :style="{ marginLeft: '12px' }" />
  </div>
</template>

<script>
/*
	import DateTimePicker from '@/components/inputs/DateTimePicker'

	<DateTimePicker :point="new Date ().toISOString ()" ref="dateTimePicker1" />
*/

import TimePicker from '@/components/inputs/TimePicker'
import DatePicker from '@/components/inputs/DatePicker'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { props } from './keys/props'
import { watch } from './keys/watch'

export default {
  components: {
    DatePicker,
    TimePicker
  },
  data,
  methods,
  mounted,
  props,
  watch
}
</script>
