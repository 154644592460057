import moment from 'moment'
import { parseMoment } from '@/utilities/moment/parse'
import { monthNums } from '@/utilities/Date/constants'

export function mounted() {
  const isValid = moment(this.point).isValid()
  console.log({ isValid }, this.point)

  if (isValid) {
    const parsed = parseMoment({
      m: this.point
    })

    this.time = parsed.time

    this.$refs.datePicker.setValue2({
      dayOfMonth: parsed.dayOfMonth,
      month: monthNums[parsed.month],
      year: parsed.orbit
    })
  }
}
